import React from "react";
import theme from "theme";
import { Theme, Text, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				CityCar Rentals
			</title>
			<meta name={"description"} content={"Испытайте острые ощущения с каждой милей"} />
			<meta property={"og:title"} content={"CityCar Rentals"} />
			<meta property={"og:description"} content={"Испытайте острые ощущения с каждой милей"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66701dce8d4a0c0020308b14/images/2-6.jpg?v=2024-06-17T11:31:25.063Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-10">
			<Override slot="SectionContent" md-margin="0px 35px 0px 35px" sm-margin="0px 15px 0px 15px" />
			<Text margin="0px 0px 15px 0px" font="normal 700 42px/1.2 --fontFamily-sans" text-align="center">
				Ознакомьтесь с нашими услугами
			</Text>
			<Text margin="0px 0px 50px 0px" font="--lead" color="#626970" text-align="center">
				В CityCar Rentals мы предоставляем больше, чем просто аренду автомобиля; мы обеспечиваем непревзойденные впечатления от вождения, начинающиеся с того момента, когда вы входите в нашу дверь. Наши услуги созданы, чтобы удовлетворить требовательных энтузиастов, гарантируя, что каждый аспект вашей аренды отвечает вашим высоким ожиданиям. Независимо от того, ищете ли вы увлекательную поездку для особого события или просто отдых на выходных, у нас есть идеальный маслкар, который ждет вас.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="24px"
				lg-grid-gap="25px 25px"
				md-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/1-3.jpg?v=2024-06-14T11:27:28.674Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 10px 0px"
						lg-margin="0px 0px 15px 0px"
					/>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Разнообразный выбор: Все лучшее из Детройта. От купе-пони и седанов до мощных внедорожников и пикапов наш автопарк оборудован таким образом, чтобы удовлетворить вкусы всех наших клиентов.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/1-2.jpg?v=2024-06-14T11:27:28.682Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Подготовка к производительности: Каждый автомобиль настроен к совершенству, предлагая не просто поездку, а опыт, резонирующий с ревом мощности и плавностью передовой техники.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-1.jpg?v=2024-06-14T11:27:28.736Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Мы поддерживаем наши транспортные средства в идеальном состоянии, как эстетически, так и механически, гарантируя, что они всегда готовы к выставкам.
						</Text>
					</LinkBox>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					md-margin="0px 0px 0 0px"
					margin="0px 0px 0 0px"
					display="flex"
					flex-direction="column"
					justify-content="flex-end"
					padding="15px 15px 15px 15px"
					background="linear-gradient(180deg,rgba(4, 8, 12, 0) 0%,rgba(0, 0, 0, 0.8) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/2-3.jpg?v=2024-06-14T11:27:28.673Z) center/cover repeat scroll padding-box"
					height="250px"
				>
					<LinkBox align-items="flex-start" margin="0px 0px 15px 0px" href="/">
						<Text margin="0px 0px 0 0px" font="normal 400 20px/1.2 --fontFamily-sans" color="--light">
							Простой процесс: от бронирования до возврата наши процессы оптимизированы и ориентированы на клиента, обеспечивая плавную и приятную работу.
						</Text>
					</LinkBox>
				</Box>
			</Box>
		</Section>
		<Components.Cta>
			<Override slot="text">
				Присоединяйтесь, чтобы узнать больше
			</Override>
			<Override slot="text1">
				Это лишь краткий обзор того, что мы предлагаем в CityCar Rentals. Откройте для себя полную информацию о том, что CityCar Rentals может предложить, связавшись с нами или посетив нас лично. Мы приглашаем вас ощутить полный спектр наших услуг и испытать ощущение от вождения современного маслкара. Наша команда с радостью познакомит вас с нашим автопарком и поможет выбрать идеальный автомобиль для ваших нужд.
			</Override>
			<Override slot="box2" align-self="center" />
			<Override slot="image" src="https://uploads.quarkly.io/666c07bf8d4a0c0020306ec3/images/1-1%20%281%29.jpg?v=2024-06-14T11:27:28.666Z" />
		</Components.Cta>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6666e55e1d712a002334ca50"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});